/* eslint-disable max-len */
import { Typography, Alert, Space, Button, Divider, Flex, theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearIntegrationResult } from '../../integration/redux/IntegrationActions';
import NavigationButtons from './NavigationButtons';

const { Paragraph, Title } = Typography;

export const integrationErrorMap = {
  0: (
    <Typography.Paragraph>
      We couldn't fetch your page. It probably has nothing to do with your Prerender integration. It can happen when
      your site doesn't respond with an HTTP200, is not available publicly on the Internet, or has some other type of
      traffic filtering. Please adjust your site's settings and try again.
    </Typography.Paragraph>
  ),
  1: (
    <div>
      <Typography.Paragraph>
        Looks like the integration is not working as expected. Failures could be caused by the following reasons:{' '}
      </Typography.Paragraph>
      <ul>
        <li>Integration misconfiguration</li>
        <li>IP restrictions on your website</li>
        <li>Bot restrictions on your website</li>
        <li>Using Cache solution</li>
      </ul>
      <Typography.Paragraph>We recommend that you try one of the following steps:</Typography.Paragraph>
      <ul>
        <li>Check your integration</li>
        <li>
          Whitelist our service IPs that you can find{' '}
          <Typography.Link href="https://docs.prerender.io/docs/22-ip-addresses" target="_blank">
            here
          </Typography.Link>
        </li>
        <li>Check your CDN configuration</li>
      </ul>
    </div>
  ),
  2: (
    <Typography.Paragraph>
      We couldn't fetch your page. It probably has nothing to do with your Prerender integration. It can happen when
      your site doesn't respond with an HTTP200, is not available publicly on the Internet, or has some other type of
      traffic filtering. Please adjust your site's settings and try again.
    </Typography.Paragraph>
  ),
  3: (
    <div>
      <Typography.Paragraph>
        Seems like your integration is almost perfect. We have managed to fetch your site, and we also received a
        request from your backend to fetch the prerendered content, but something unexpected has happened while
        rendering it. Please double-check if your site can be fetched properly with{' '}
        <Typography.Link href="https://docs.prerender.io/docs/33-overview-of-prerender-crawlers" target="_blank">
          Prerender.io's user agents
        </Typography.Link>
        .
      </Typography.Paragraph>
    </div>
  ),
};

function VerificationError({ setCurrentStep }) {
  const { token } = theme.useToken();
  const validationResult = useSelector((state) => state.onboarding.validationResult);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoBack = (path) => {
    dispatch(clearIntegrationResult());
    navigate(`/integration-wizard/${path}`);
  };

  return (
    <Space size={token.sizeXXL} direction="vertical">
      <Alert
        message={<Title level={4}>Integration not detected</Title>}
        type="error"
        showIcon
        description={
          validationResult?.errors?.length
            ? integrationErrorMap[validationResult?.errors[0]]
            : 'We reached your site but can’t detect an integration.'
        }
      />

      <Flex justify="center" gap="large">
        <div>
          <Title level={5}>Review the integration guide</Title>
          <Paragraph>First, double-check everything looks right.</Paragraph>
          <Button
            type="link"
            size="small"
            style={{ padding: 0 }}
            onClick={() => {
              setCurrentStep(1);
              handleGoBack('integration');
            }}
          >
            Go to Guides
          </Button>
        </div>
        <div>
          <Divider type="vertical" style={{ height: '100%' }} />
        </div>
        <div>
          <Title level={5}>Need help?</Title>
          <Paragraph>Schedule a free onboarding call.</Paragraph>
        </div>
      </Flex>
      <NavigationButtons onGoBack={() => handleGoBack('verify-integration')} />
    </Space>
  );
}

export default VerificationError;
