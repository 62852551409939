import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from '../auth';
import config from '../../assets/config';

export const restApi = createApi({
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: `${config.apiUrl.restServer}/v3`,
      prepareHeaders: (headers) => {
        const accessToken = getAccessToken();
        if (accessToken) {
          // headers.set('x-prerender-authorization', accessToken);
          headers.set('Authorization', `Bearer ${accessToken}`);
        }
        return headers;
      },
    }),
    {
      retryCondition: (error, args, extraArgs) => {
        if (extraArgs.attempt > 3) {
          return false;
        }
        const isGetError = typeof args === 'string' || args?.method === 'GET'; // query: () => '/path' || ({ url: '/path', method: 'GET' })

        if ((error.status >= 500 && error.status <= 599 && isGetError) || error?.status === 'FETCH_ERROR') {
          return true;
        }
        return false;
      },
    }
  ),
  tagTypes: [
    'EnvironmentHistory',
    'Environment',
    'Coupon',
    'TeamInvites',
    'AuditLog',
    'domainStatistics',
    'domainDetails',
    'rankedDomains',
    'availableDomains',
    'schedulerSettings',
    'customerSupport',
    'URL',
    'CacheClearStatus',
    'Notifications',
    'CachedPages',
    'URLParameters',
    'Domains',
    'AccountDetails',
    'IntegrationDomain',
  ],
  endpoints: () => ({}),
});
