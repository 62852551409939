enum QueryParameters {
  Sort = 'sort',
  SortDirection = 'sortDirection',
  Domain = 'domain',
}

type Sort = 'number_of_urls' | 'created_at';

export type DomainListQueryParameters = Partial<{
  [QueryParameters.Sort]: Sort;
  [QueryParameters.SortDirection]: 'DESC' | 'ASC';
  [QueryParameters.Domain]: string;
}>;

export function isDomainListParam(name: string): name is QueryParameters {
  return Object.values(QueryParameters).includes(name as QueryParameters);
}

export function buildDomainListQueryParameters(data: Record<string, string>): DomainListQueryParameters {
  return Object.entries(data).reduce((acc, [name, value]) => {
    if (!isDomainListParam(name)) return acc;

    const paramName = name as QueryParameters;

    switch (paramName) {
      case QueryParameters.Sort:
        acc[paramName] = value as Sort;
        break;
      case QueryParameters.SortDirection:
        acc[paramName] = value as 'DESC' | 'ASC';
        break;
      case QueryParameters.Domain:
        acc[paramName] = value;
        break;
    }

    return acc;
  }, {});
}
