import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import { Alert, Card, Flex, Input, Table, Typography, Button, message, TablePaginationConfig } from 'antd';
import { NoticeType } from 'antd/lib/message/interface';
import { SearchOutlined } from '@ant-design/icons';
import { isFree } from '@prerender/billing-shared';

import AdminTemplate from '../../../layout/AdminTemplate';
import { getDisplayRange } from '../../../utils/getDisplayRange';
import useFeatureFlag, { FeatureFlag } from '../../../hooks/useFeatureFlag';
import useQueryStringParameters from '../../../utils/useQueryStringParameters';
import useQueryStringPagination from '../../../utils/useQueryStringPagination';
import { useCharbeePlanId, useIsPrerenderUser, usePrerenderEmail } from '../../../hooks/usePrerenderUser';
import {
  useGetDomainStatisticsQuery,
  useExportDomainsCsvMutation,
  useAddAndVerifyDomainMutation,
  ExportStatus,
} from '../api/domainManagerApiSlice';
import { useEvent } from '../../events/hooks/useEvent';
import ExportButton from '../../../components/ExportButton';
import ExportCSVModal from '../../SeoReport/components/ExportCSVModal';
import AddDomainModal from './AddDomainModal';
import IntegrationFailedModal from './IntegrationFailedModal';
import LinksCell from './LinksCell';
import VerifiedStateCell from './VerifiedStateCell';
import ActionsCell from './ActionsCell';
import type Domain from '../Domain';
import { buildDomainListQueryParameters, DomainListQueryParameters } from './DomainListQueryParameters';

const { Title, Text } = Typography;

const META_TAGS = {
  title: 'Domain Manager List - Prerender.io',
};

const PAGE_SIZE = 100;

const getResponseMessage = (status: ExportStatus, email?: string): { content: string; type: NoticeType } => {
  switch (status) {
    case ExportStatus.Queued:
      return {
        content: email
          ? `Your export is processing and will take a couple of minutes. We\'ll email it to you at "${email}" when it\'s ready.`
          : 'We have scheduled your export. You will get a download link in your email once the export is ready.',
        type: 'success',
      };
    case ExportStatus.NotQueue:
      return {
        content:
          // eslint-disable-next-line max-len
          "Click start to export all your cached URLs into a CSV. Please note that exporting URLs happens in the background and you'll be notified by email once it's done.",
        type: 'info',
      };
    case ExportStatus.AlreadyExists:
      return {
        content: 'CSV export has been already requested. Please try again later.',
        type: 'warning',
      };

    default:
      return {
        content: "Something bad has happened. We're looking into it...",
        type: 'error',
      };
  }
};

function parseApiResponse(data: Domain[]): DomainListItem[] {
  return data.map((item) => {
    const formattedNumberOfUrls = new Intl.NumberFormat().format(item.number_of_urls);
    return {
      id: item.id,
      domain: item.domain,
      created_at: item.created_at ? dayjs(item.created_at).format('YYYY-MM-DD') : '-',
      number_of_urls: formattedNumberOfUrls,
      deletable: item.deletable,
      verified: item.middleware_installed,
    };
  });
}

type DomainListItem = Omit<Domain, 'middleware_installed' | 'number_of_urls'> & {
  verified: boolean;
  number_of_urls: string;
};

const DomainsListPage = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [showExportModal, setShowExportModal] = useState(false);
  const [showAddDomainModal, setShowAddDomainModal] = useState(false);

  const { queryStrings, updateParameter, updateMultipleParameters } =
    useQueryStringParameters<DomainListQueryParameters>(buildDomainListQueryParameters);
  const { pagination, updatePagination } = useQueryStringPagination(PAGE_SIZE);
  const [searchInput, setSearchInput] = useState(queryStrings.domain || '');

  const { data, error, isFetching } = useGetDomainStatisticsQuery(
    { ...pagination, ...queryStrings },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [exportDomainManagerCsv] = useExportDomainsCsvMutation();
  const [verifyIntegration, verifyIntegrationResult] = useAddAndVerifyDomainMutation();

  const canUseDomainDetails = useFeatureFlag([FeatureFlag.DomainsDetails]);
  const { track } = useEvent();

  const totalDomains = data?.totalCount || 0;

  const userEmail = usePrerenderEmail();
  const chargebeePlanId = useCharbeePlanId();
  const isPrerenderUser = useIsPrerenderUser();

  const domains: DomainListItem[] = React.useMemo(() => {
    if (data) {
      return parseApiResponse(data.data);
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (error) {
      message.error('Failed to fetch domain statistics');
    }
  }, [error]);

  const handleTableChange = (
    latestPagination: TablePaginationConfig,
    _filters,
    sorter: { field: DomainListQueryParameters['sort']; order: 'ascend' | 'descend' }
  ) => {
    updateMultipleParameters({
      sort: sorter.field,
      sortDirection: sorter.order === 'descend' ? 'DESC' : 'ASC',
    });
    if (latestPagination.current !== pagination.page)
      updatePagination(latestPagination.current, latestPagination.pageSize);
  };

  const debouncedSetTableParams = useCallback(
    debounce((newInput: string) => {
      updateParameter('domain', newInput);
    }, 1500),
    []
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInput = e.target.value;
    setSearchInput(newInput);

    debouncedSetTableParams(newInput);
  };

  const displayRange = getDisplayRange({ current: pagination.page, pageSize: pagination.pageSize }, totalDomains);
  const paidPlan = !isFree(chargebeePlanId);

  const handleExportCsv = () => {
    const exportParams = {
      sort: queryStrings.sort,
      sortDirection: queryStrings.sortDirection,
      q: queryStrings.domain,
    };

    exportDomainManagerCsv(exportParams)
      .unwrap()
      .then((result) => {
        messageApi.open(getResponseMessage(result.status, userEmail));
      })
      .catch(() => {
        messageApi.error({ content: 'Failed to export CSV' });
      })
      .finally(() => {
        setShowExportModal(false);
      });
  };

  const onVerifyIntegration = (url: string) => {
    verifyIntegration(`https://${url}`)
      .unwrap()
      .then((result) => {
        track('Domain integration verified', {
          domain: url,
          subscription_plan: chargebeePlanId,
          prerenderIntegrationVerified: result.working,
        });
        if (result.working) {
          messageApi.success('Domain integration verified successfully');
        }
      })
      .catch(() => {
        track('Unexpected error - Domain integration verified', {
          domain: url,
          subscription_plan: chargebeePlanId,
        });

        messageApi.error('Failed to verify the domain integration, please try again later');
      });
  };

  const onOpenAddDomainModal = () => {
    track('Add domain clicked', { subscription_plan: chargebeePlanId });
    setShowAddDomainModal(true);
  };

  const onCloseVerifyIntegrationModal = () => {
    verifyIntegrationResult.reset();
  };

  const isLoading = isFetching || verifyIntegrationResult.isLoading;

  const renderVerifiedStateCell = React.useCallback(
    (_text, record: DomainListItem) => <VerifiedStateCell verified={record.verified} />,
    []
  );
  const renderLinksCell = React.useCallback(
    (_text, record: DomainListItem) => <LinksCell id={record.id} domain={record.domain} />,
    []
  );
  const renderActionsCell = React.useCallback(
    (_text, record: DomainListItem) => (
      <ActionsCell
        id={record.id}
        domain={record.domain}
        deletable={record.deletable}
        verified={record.verified}
        onVerifyIntegration={() => onVerifyIntegration(record.domain)}
      />
    ),
    []
  );

  const sortDirection = queryStrings.sortDirection === 'ASC' ? 'ascend' : 'descend';

  return (
    <AdminTemplate metaTags={META_TAGS}>
      {contextHolder}
      {canUseDomainDetails && (
        <ExportCSVModal
          onClose={() => setShowExportModal(false)}
          onExport={handleExportCsv}
          showModal={showExportModal}
          text="Export Domains list as CSV?"
        />
      )}

      {isPrerenderUser && <AddDomainModal open={showAddDomainModal} onClose={() => setShowAddDomainModal(false)} />}
      <IntegrationFailedModal
        open={verifyIntegrationResult.isSuccess && !verifyIntegrationResult.data?.working}
        onClose={onCloseVerifyIntegrationModal}
        errors={verifyIntegrationResult.data?.errors}
      />

      <Flex gap={16} vertical={true} style={{ marginBottom: '16px' }}>
        <div>
          <Title level={2}>Domain Manager</Title>
          <Typography.Paragraph type="secondary">
            Monitor your activity and adjust settings for each cached domain.
          </Typography.Paragraph>
        </div>

        <Alert message="Please note the list refreshes every 24 hours" type="info" showIcon />
        <Card
          title={
            <Flex align="center" justify="space-between">
              <Typography.Title level={5}>My Domains</Typography.Title>
              {isPrerenderUser && (
                <Button type="primary" onClick={onOpenAddDomainModal}>
                  Add Domain
                </Button>
              )}
            </Flex>
          }
        >
          <Flex justify="space-between">
            <Input
              placeholder="search domain"
              allowClear
              value={searchInput}
              onChange={onInputChange}
              prefix={<SearchOutlined style={{ color: '#d9d9d9' }} />}
              style={{ marginBottom: 16, width: '30%' }}
            />
            <div>
              <Text>
                Displaying {displayRange} domains from {Intl.NumberFormat().format(totalDomains)}
              </Text>
              {canUseDomainDetails && (
                <ExportButton isFreePlan={!paidPlan} hasSelectedUrls={false} onClick={() => setShowExportModal(true)} />
              )}
            </div>
          </Flex>
          <Table
            loading={isLoading}
            pagination={{
              current: pagination.page,
              pageSize: pagination.pageSize,
              total: totalDomains,
            }}
            dataSource={domains}
            onChange={handleTableChange}
            bordered
            rowKey="domain"
          >
            <Table.Column title="Domain" dataIndex="domain" key="domain" width="90%" />
            <Table.Column
              title="Created On"
              dataIndex="created_at"
              key="created_at"
              sorter
              defaultSortOrder={queryStrings.sort === 'created_at' ? sortDirection : undefined}
              className="col-min"
            />
            <Table.Column
              title="URLs"
              dataIndex="number_of_urls"
              key="number_of_urls"
              sorter
              defaultSortOrder={queryStrings.sort === 'number_of_urls' ? sortDirection : undefined}
            />
            {isPrerenderUser && (
              <Table.Column title="Integration" dataIndex="verified" key="verified" render={renderVerifiedStateCell} />
            )}
            <Table.Column title="Links" render={renderLinksCell} />
            <Table.Column title="Actions" width="5%" render={renderActionsCell} />
          </Table>
        </Card>
      </Flex>
    </AdminTemplate>
  );
};

export default DomainsListPage;
