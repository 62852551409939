import React from 'react';
import { Modal, Form, Input, Typography, message } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';

import { useEvent } from '../../events/hooks/useEvent';
import { useAddAndVerifyDomainMutation } from '../api/domainManagerApiSlice';
import { integrationErrorMap } from '../../integrationWizard';
import { useCharbeePlanId } from '../../../hooks/usePrerenderUser';

type AddDomainModalProps = {
  open: boolean;
  onClose: () => void;
};

function AddDomainModal({ open, onClose }: AddDomainModalProps) {
  const [addDomain, addDomainResult] = useAddAndVerifyDomainMutation();
  const [messageApi, messageContext] = message.useMessage();

  const chargebeePlanId = useCharbeePlanId();

  const { track } = useEvent();

  const onFinish = (values: { url: string }) => {
    addDomain(values.url)
      .unwrap()
      .then((result) => {
        track('New domain added', {
          domain: values.url,
          subscription_plan: chargebeePlanId,
          prerenderIntegrationVerified: result.working,
        });
        if (result.working) {
          messageApi.success('Domain added and verified successfully.');
          onClose();
        }
      })
      .catch(() => {
        track('Unexpected error - New domain added', {
          domain: values.url,
          subscription_plan: chargebeePlanId,
          prerenderIntegrationVerified: false,
        });
      });
  };

  const showFeedback = addDomainResult.isSuccess && !addDomainResult.data.working;

  const integrationErrorDescription = React.useMemo(() => {
    if (addDomainResult.data?.errors.length) {
      return integrationErrorMap[addDomainResult.data.errors[0]];
    }

    return 'We reached your site but can’t detect an integration.';
  }, [addDomainResult.data]);

  const onCancel = () => {
    addDomainResult.reset();
    onClose();
  };

  return (
    <>
      {messageContext}
      <Modal
        title="Add New Domain"
        open={open}
        onCancel={onCancel}
        destroyOnClose
        okButtonProps={{ htmlType: 'submit', form: 'new-domain', loading: addDomainResult.isLoading }}
        okText="Add"
        cancelText={showFeedback ? 'Close' : 'Cancel'}
        footer={(_, { OkBtn, CancelBtn }) =>
          showFeedback ? <CancelBtn /> : [<CancelBtn key={1} />, <OkBtn key={2} />]
        }
      >
        <Form name="new-domain" onFinish={onFinish} layout="vertical">
          <Form.Item
            label="URL"
            name="url"
            rules={[{ required: true, type: 'url' }]}
            validateStatus={addDomainResult.isError ? 'error' : undefined}
            help={addDomainResult.isError ? 'An unexpected error occurred. Please try again later.' : undefined}
          >
            <Input prefix={<GlobalOutlined />} placeholder="https://my-new-domain.com" disabled={showFeedback} />
          </Form.Item>
        </Form>

        {showFeedback && (
          <>
            <Typography.Title level={4}>Domain added, but not verified.</Typography.Title>
            {integrationErrorDescription}
            <Typography.Paragraph>
              You can verify again your domain by using the "Verify" Action on the last column of the table.
            </Typography.Paragraph>
          </>
        )}
      </Modal>
    </>
  );
}

export default AddDomainModal;
