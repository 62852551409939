import { useEffect, useState, createContext, useContext } from 'react';
import { loadSpace } from '@usersnap/browser';
import dayjs from 'dayjs';

import { userSnapSpaceApiKey } from '../../assets/config';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';

const UserSnapApiContext = createContext();

const timePeriodChecker = (startTime) => {
  const now = dayjs();
  const endTime = dayjs(startTime).add(1, 'week');
  return now.isAfter(endTime);
};

export const UserSnapApiProvider = ({ children }) => {
  const [usersnapApi, setUsersnapApi] = useState(null);
  const user = usePrerenderUser();

  const loadUserSnapSdk = async () => {
    try {
      const api = await loadSpace(userSnapSpaceApiKey);
      await api.init({
        user: {
          userId: user.id,
          email: user.email,
          plan: user.planName,
          signupDate: user.signupDate?.toISOString(),
        },
      });
      setUsersnapApi(api);
    } catch (error) {
      console.error('Error initializing UserSnap', error);
    }
  };

  useEffect(() => {
    if (!usersnapApi && user.hasSession) {
      loadUserSnapSdk();
    }
  }, [user]);

  useEffect(() => {
    if (usersnapApi && user.hasSession && !!user.trackingCodeInstalledDate) {
      const showPostintegrationUsersnap = timePeriodChecker(user.trackingCodeInstalledDate);
      if (showPostintegrationUsersnap) {
        usersnapApi.logEvent('post-integration-1-week-questionnaire');
      }
    }
  }, [user.trackingCodeInstalledDate, user.hasSession, usersnapApi]);

  return <UserSnapApiContext.Provider value={usersnapApi}>{children}</UserSnapApiContext.Provider>;
};

export const useUserSnap = () => {
  const api = useContext(UserSnapApiContext);

  const openUserSatisfactionWidget = () => api.logEvent('open_user_satisfaction');
  const openIssueTrackingWidget = () => api.logEvent('open_issue_tracking');
  const openChurnQuestionnaire = () => api.logEvent('downgrade_from_paid');

  return {
    api,
    openUserSatisfactionWidget,
    openIssueTrackingWidget,
    openChurnQuestionnaire,
  };
};
