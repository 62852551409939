import { isFree } from '@prerender/billing-shared';
import { useState, useEffect } from 'react';
import { getAccessToken } from '../../features/auth';
import config from '../../assets/config';

import { usePrerenderUser } from '../../hooks/usePrerenderUser';

const getTawktoSignature = () => {
  const baseUrl = `${config.apiUrl.restServer}/v3`;

  const accessToken = getAccessToken();

  return fetch(`${baseUrl}/tawkto/sign`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
  })
    .then((r) => r.json())
    .catch((err) => {
      console.error(`Failed to get signature for tawkto`);
      console.error(err);
    });
};

const getTawkToInstance = function (callback) {
  if (window && window.Tawk_API && window.Tawk_API.getStatus !== undefined) {
    callback(window.Tawk_API);
    return;
  }
  setTimeout(function () {
    getTawkToInstance(callback);
  }, 0);
};

export function useTawkTo(shouldLoad) {
  //     Show tawkto:
  // - Before user is integrated, or
  // - User is in trial period, or
  // - User is in free plan

  const user = usePrerenderUser();

  const isUserIntegrated = user && user.trackingCodeInstalled;
  const isFreePlan = user && isFree(user.chargeBeePlanId);

  const [userIdentified, setUserIdentified] = useState(false);

  useEffect(() => {
    if (user.hasSession && user.id && userIdentified === false) {
      if (!shouldLoad) {
        console.log(`No tawkto.`);
        return;
      }

      setUserIdentified(true);

      if (isUserIntegrated && !isFreePlan) {
        console.log(`No tawkto.`);
        return;
      }

      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_API.autoStart = false;
      window.Tawk_LoadStart = new Date();
      let s1 = document.createElement('script');
      let s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = `https://embed.tawk.to/${config.tawkToIds}`;
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);

      getTawktoSignature().then(({ idSignature, emailSignature }) => {
        getTawkToInstance((tawkto) => {
          tawkto.logout((tawkto_logout_error) => {
            if (tawkto_logout_error) {
              console.error({ tawkto_logout_error });
            }

            tawkto.login(
              {
                hash: idSignature,
                userId: `${user.id}`,
                email: user.adminEmail,
              },
              function (tawk_login_error) {
                if (tawk_login_error) {
                  console.error({ tawk_login_error });
                }

                tawkto.start(); // Start without showing widget

                tawkto.setAttributes(
                  {
                    companyId: `${user.companyId}`,
                    email: user.email,
                    hash: emailSignature,
                  },
                  function (tawk_attribute_error) {
                    if (tawk_attribute_error) console.error({ tawk_attribute_error });
                    else {
                      tawkto.start({
                        showWidget: true, // Show widget after setting attributes
                      });
                    }
                  }
                );
              }
            );
          });
        });
      });
    }
  }, [user, userIdentified, shouldLoad]);
}
