import { Alert, Typography } from 'antd';
import { isFree } from '@prerender/billing-shared';
import { useNavigate } from 'react-router-dom';
import { formatDays } from '../../assets/dateFormatter';

import './planAlert.css';
import { useEvent } from '../events/hooks/useEvent';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';

const { Link, Text } = Typography;

function UpgradeYourPlan() {
  const navigate = useNavigate();
  const { track } = useEvent();
  const prerenderUser = usePrerenderUser();

  return (
    <Link
      onClick={() => {
        track('Recache settings learn more link clicked', {
          subscription_plan: prerenderUser?.chargebeePlanId,
        });
        navigate('/billing/plans');
      }}
    >
      upgrade your plan
    </Link>
  );
}

function LowCacheExpiration({ minDays }) {
  return (
    <>
      <Text>Cache expiration below your plan's {formatDays(minDays)} minimum. For shorter durations, </Text>
      <UpgradeYourPlan />
      <Text>.</Text>
    </>
  );
}

function HighCacheExpiration({ maxDays }) {
  return (
    <>
      <Text>Cache expiration exceeds your plan's {formatDays(maxDays)} limit. For longer durations, </Text>
      <UpgradeYourPlan />
      <Text>.</Text>
    </>
  );
}

function BetterPlanFound({ savedCost, suggestedEstimatedPlanName }) {
  const navigate = useNavigate();
  const { track } = useEvent();
  const prerenderUser = usePrerenderUser();
  return (
    <>
      <Text>
        Based on your current usage, you're projected to exceed your plan's limits, which will result in overage fees.{' '}
      </Text>
      <Link
        onClick={() => {
          track('Recache settings better plan found upgrade link clicked', {
            subscription_plan: prerenderUser?.chargebeePlanId,
          });
          navigate('/billing/plans');
        }}
      >
        Upgrade{' '}
      </Link>
      <Text>
        to {suggestedEstimatedPlanName} plan save up to ${savedCost}/month and avoid additional costs.
      </Text>
    </>
  );
}

function NotSupported() {
  return (
    <>
      <Text>This cache expiration is no longer supported and </Text>
      <Text strong>irreversible once changed.</Text>
    </>
  );
}

export default function getPlanAlert({ prerenderUser, suggestedPlan, savedCost, isInInterval, cacheFreshness }) {
  const cacheFreshnessIntervalMin =
    prerenderUser.plan.cacheRefreshInterval &&
    Array.isArray(prerenderUser.plan.cacheRefreshInterval) &&
    prerenderUser.plan.cacheRefreshInterval.length == 2
      ? prerenderUser.plan.cacheRefreshInterval[0]
      : 1;
  const cacheFreshnessIntervalMax =
    prerenderUser.plan.cacheRefreshInterval &&
    Array.isArray(prerenderUser.plan.cacheRefreshInterval) &&
    prerenderUser.plan.cacheRefreshInterval.length == 2
      ? prerenderUser.plan.cacheRefreshInterval[1]
      : 1;
  const getAlert = () => {
    if (isFree(prerenderUser?.plan?.current?.id)) {
      return null;
    }
    // legacy users never have suggested plan
    if (!isInInterval && !prerenderUser.isMeteredBilling && cacheFreshness < cacheFreshnessIntervalMin) {
      return {
        item: <LowCacheExpiration minDays={cacheFreshnessIntervalMin} />,
        type: 'warning',
      };
    }
    if (!isInInterval && suggestedPlan && cacheFreshness < cacheFreshnessIntervalMin) {
      return {
        item: <LowCacheExpiration minDays={cacheFreshnessIntervalMin} />,
        type: 'warning',
      };
    }
    if (!isInInterval && suggestedPlan && cacheFreshness > cacheFreshnessIntervalMax) {
      return {
        item: <HighCacheExpiration maxDays={cacheFreshnessIntervalMax} />,
        type: 'warning',
      };
    }
    if (!isInInterval && !suggestedPlan) {
      return {
        item: <NotSupported />,
        type: 'warning',
      };
    }
    if (isInInterval && suggestedPlan && savedCost > 0) {
      return {
        item: <BetterPlanFound savedCost={savedCost} suggestedEstimatedPlanName={suggestedPlan.name} />,
        type: 'error',
      };
    }
    return null;
  };

  const alert = getAlert();
  if (!alert) {
    return {
      item: <></>,
      showAlert: false,
    };
  }

  return {
    item: <Alert showIcon type={alert.type} message={alert.item} className="recache-interval-alert" />,
    showAlert: true,
    type: alert.type,
  };
}
