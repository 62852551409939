import { Alert, Button, Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { isFree } from '@prerender/billing-shared';

import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useEvent } from '../events/hooks/useEvent';
import './DashboardAlert.css';
import { setAlertClosed, isAlertClosed, AlertCodes } from './alerts';
import { isSSOSite } from '../auth/keycloakAuth';

const { Text } = Typography;

const InviteBillingManagerAlert = () => {
  const user = usePrerenderUser();
  const { track } = useEvent();
  const navigate = useNavigate();
  const location = useLocation();

  const onInviteClick = () => {
    track('Invite Billing Manager Alert Clicked');
    navigate('/security/team-members');
  };

  const afterClose = () => {
    setAlertClosed(AlertCodes.INVITE_BILLING_MANAGER);
  };

  const isClosed = isAlertClosed(AlertCodes.INVITE_BILLING_MANAGER);
  if (isClosed) return null;

  const showAlert =
    !isSSOSite() &&
    !isFree(user.chargebeePlanId) &&
    !user.hasBillingManager &&
    location.pathname !== '/security/team-members';

  if (showAlert) {
    return (
      <Alert
        message={<Text strong>Friendly Reminder: Invite Billing Managers To Your Team</Text>}
        description={
          'Don’t forget to add Billing Managers to your Prerender account. ' +
          'You’ll help declutter your inbox and guarantee that invoicing emails ' +
          'go to the right users.'
        }
        banner
        type="success"
        action={
          <Button type="primary" onClick={onInviteClick}>
            Add Billing Managers
          </Button>
        }
        afterClose={afterClose}
        closable
      />
    );
  }
  return <></>;
};

export default InviteBillingManagerAlert;
