import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEvent } from '../events/hooks/useEvent';
import { useCharbeePlanId } from '../../hooks/usePrerenderUser';

export const useIntegrationWizardSteps = (stepsConfig) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [prevStep, setPrevStep] = useState(-1);
  const { track } = useEvent();
  const chargebeePlanId = useCharbeePlanId();

  useEffect(() => {
    if (stepsConfig) {
      const localStorageCurrentStep = localStorage.getItem('integrationWizardStep');
      const localStorageCurrentItem = stepsConfig?.find((item) => item.code === localStorageCurrentStep);
      if (localStorageCurrentStep && localStorageCurrentItem) {
        const storedIndex = stepsConfig?.findIndex((item) => item.code === localStorageCurrentStep);
        if (storedIndex !== -1) {
          setCurrentStep(storedIndex);
        }
        navigate(`/integration-wizard/${localStorageCurrentItem.path}`);
      } else {
        if (location.pathname !== '/integration-wizard') navigate(`/integration-wizard`);
      }
    }
  }, [stepsConfig]);

  const onGoBack = useCallback(() => {
    setCurrentStep(currentStep - 1);
    setPrevStep(currentStep);
    localStorage.setItem('integrationWizardStep', stepsConfig[currentStep - 1].code);
    navigate(`/integration-wizard/${stepsConfig[currentStep - 1].path}`);
  }, [currentStep, navigate, stepsConfig]);

  const onGoNext = useCallback(() => {
    if (currentStep !== stepsConfig.length - 1) {
      setCurrentStep(currentStep + 1);
      setPrevStep(currentStep);
      localStorage.setItem('integrationWizardStep', stepsConfig[currentStep + 1].code);
      navigate(`/integration-wizard/${stepsConfig[currentStep + 1].path}`);
    } else {
      track('Integration wizard: Upgrade now button clicked', { subscription_plan: chargebeePlanId });
      navigate('/billing/plans');
    }
  }, [currentStep, navigate, stepsConfig]);

  const goToStart = useCallback(() => {
    const domainStep = 0;
    setCurrentStep(domainStep);
    setPrevStep(currentStep);
    localStorage.setItem('integrationWizardStep', stepsConfig[domainStep].code);
    navigate(`/integration-wizard/${stepsConfig[domainStep].path}`);
  }, [navigate, stepsConfig]);

  return { onGoBack, onGoNext, goToStart, stepsConfig, currentStep, setCurrentStep, prevStep };
};
