import React from 'react';
import { Flex, Button, Typography, theme } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

export type PrevNextPaginationProps = {
  onPrevClick: () => void;
  onNextClick: () => void;
  page: number;
  pageSize: number;
  currentPageAmount: number;
};

function PrevNextPagination({
  onNextClick,
  onPrevClick,
  page,
  pageSize,
  currentPageAmount = 0,
}: PrevNextPaginationProps) {
  const { token } = theme.useToken();

  const firstPageDisplayed = pageSize * (page - 1) + 1;
  const lastPageDisplayed = firstPageDisplayed + currentPageAmount - 1;

  return (
    <Flex justify="space-between" align="center" style={{ margin: token.marginSM }}>
      <Button icon={<ArrowLeftOutlined />} onClick={onPrevClick} disabled={!onPrevClick}>
        Previous Page
      </Button>
      <div>
        <Typography.Text type="secondary">
          Page {page}, {firstPageDisplayed} - {lastPageDisplayed}
        </Typography.Text>
      </div>
      <Button icon={<ArrowRightOutlined />} iconPosition="end" onClick={onNextClick} disabled={!onNextClick}>
        Next Page
      </Button>
    </Flex>
  );
}

export default PrevNextPagination;
