import { http, HttpResponse } from 'msw';
import { faker } from '@faker-js/faker';

const getDomainsList = http.get('http://localhost:3030/v3/domain-statistics', () => {
  return HttpResponse.json({
    data: Array.from({ length: 100 }, (_, index) => ({
      domain: faker.internet.domainName(),
      created_at: faker.date.anytime(),
      id: index,
      number_of_urls: faker.number.int({ max: 1000 }),
      deletable: faker.datatype.boolean(),
      middleware_installed: faker.datatype.boolean(),
    })),
    totalCount: 300,
  });
});

export default [getDomainsList];
