import PrerenderGoogleLogin from './components/PrerenderGoogleLogin';
import SearchConsoleActions from './components/SearchConsoleActions';
import { GoogleOAuthProvider } from '@react-oauth/google';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { usePageContent } from '../../hooks/usePageContent';

import { Flex, Typography } from 'antd';

const { Title, Text } = Typography;

const META_TAGS = {
  title: 'Google Search Console - Prerender.io',
};
const getGoogleClientID = (node_env) => {
  return node_env === 'development'
    ? '159418071530-8jce97vsdt2kpa9havt33booq7ch7r6p.apps.googleusercontent.com'
    : '573209089028-ajljj8r60pkla1ja0kpcnmuln10vrhth.apps.googleusercontent.com';
};

function GoogleSearchConsolePage() {
  const { cm } = usePageContent('Onboarding');

  if (!cm) {
    return <h1>Loading...</h1>;
  }

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <GoogleOAuthProvider clientId={getGoogleClientID(process.env.NODE_ENV)}>
        <Flex vertical style={{ marginBottom: 32 }}>
          <Flex justify="space-between">
            <Title level={2} style={{ marginBottom: 8 }}>
              Google Search Console
            </Title>
            <PrerenderGoogleLogin key="btn" />
          </Flex>
          <Text type="secondary">Add your sitemaps into Prerender to reduce and eliminate any cache misses.</Text>
        </Flex>

        <SearchConsoleActions />
      </GoogleOAuthProvider>
    </AdminTemplate>
  );
}

export default requireAuth(GoogleSearchConsolePage);
