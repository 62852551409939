import React from 'react';
import { Flex, Modal, Typography, theme } from 'antd';
import { WarningFilled } from '@ant-design/icons';

type AlertTitleProps = {
  isDowngrading: boolean;
};

function AlertTitle({ isDowngrading }: AlertTitleProps) {
  const { token } = theme.useToken();
  return (
    <Flex gap="middle" align="center">
      <WarningFilled style={{ color: token.colorWarning }} />
      <Typography.Title level={4} style={{ margin: 0 }}>
        {isDowngrading ? 'Downgrading' : 'Upgrading'} plan
      </Typography.Title>
    </Flex>
  );
}

type InsufficientRendersDisclaimerProps = {
  newPlan: any;
  onConfirm: () => void;
  onCancel: () => void;
  isDowngrading: boolean;
};

function InsufficientRendersDisclaimer({
  newPlan,
  onConfirm,
  onCancel,
  isDowngrading,
}: InsufficientRendersDisclaimerProps) {
  return (
    <Modal
      title={<AlertTitle isDowngrading={isDowngrading} />}
      open={!!newPlan}
      onOk={onConfirm}
      okText={isDowngrading ? 'Downgrade Plan Anyway' : 'Upgrade Plan'}
      okButtonProps={{
        danger: isDowngrading,
        type: 'primary',
      }}
      onCancel={onCancel}
    >
      {newPlan && (
        <Typography.Paragraph>
          <p>
            The "{newPlan.name}" plan <Typography.Text strong>contains fewer renders</Typography.Text> than your
            estimated usage.
          </p>
          <p>
            Switching to this plan might lead to exceeding the render limit with a cost of{' '}
            {newPlan.addonCostInCents / 100} per 1000 additional renders.
          </p>
        </Typography.Paragraph>
      )}
    </Modal>
  );
}

export default InsufficientRendersDisclaimer;
