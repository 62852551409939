import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signOutUser } from '../actions/prerenderUserActions';
import { restoreAlertsState } from '../features/alerts';

class SignOutPage extends Component {
  componentDidMount() {
    const { signOutUser: doSignOutUser } = this.props;

    restoreAlertsState();
    doSignOutUser();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <div />;
  }
}

function mapStateToProps() {
  return {};
}

// provide access to selected actions:
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signOutUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignOutPage);
