import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const TOOLTIP_ROOT_ID = 'global-tooltip';

const styles = {
  zIndex: 10000,
  padding: '6px 8px',
  backgroundColor: 'rgba(38, 38, 38, 0.95)',
  boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)',
  color: 'white',
  borderRadius: '6px',
  fontSize: '14px',
  maxWidth: '250px',
  fontFamily:
    "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
};

function CustomTooltip() {
  return (
    <div>
      <Tooltip id={TOOLTIP_ROOT_ID} style={styles} />
    </div>
  );
}

export default CustomTooltip;
