import React from 'react';
import { Popconfirm, Flex, message } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';

import ButtonWithRole from '../../../components/ButtonWithRole';
import { Role } from '../../PrerenderUser';
import { useCharbeePlanId, useIsPrerenderUser, useUserRole } from '../../../hooks/usePrerenderUser';
import { useEvent } from '../../events/hooks/useEvent';
import { TOOLTIP_ROOT_ID } from '../../../components/Tooltip';
import { useDeleteDomainMutation } from '../api/domainManagerApiSlice';

type ActionsCellProps = {
  domain: string;
  id: number;
  deletable: boolean;
  verified: boolean;
  onVerifyIntegration: (domain: string) => void;
};

function ActionsCell({ domain, id, deletable, verified = false, onVerifyIntegration }: ActionsCellProps) {
  const [deleteDomain, deleteDomainResult] = useDeleteDomainMutation();

  const [messageApi, messageContext] = message.useMessage();
  const role = useUserRole();
  const chargebeePlanId = useCharbeePlanId();
  const isPrerenderUser = useIsPrerenderUser();

  const { track } = useEvent();

  const onDelete = () => {
    deleteDomain(id)
      .unwrap()
      .then(() => {
        messageApi.success('Domain deleted successfully');
        track('Domain Deleted', { domain, subscription_plan: chargebeePlanId });
      })
      .catch(() => {
        messageApi.error('Failed to delete domain');
      });
  };

  return (
    <Flex gap={8} justify="center">
      {messageContext}
      {isPrerenderUser && !verified && (
        <div
          data-tooltip-id={TOOLTIP_ROOT_ID}
          data-tooltip-content={
            [Role.BillingManager, Role.TeamMember, Role.Guest].includes(role)
              ? 'Action only allowed for Account Owner and admin'
              : 'Verify Prerender integration'
          }
          data-tooltip-place="top"
        >
          <ButtonWithRole
            disabledFor={[Role.BillingManager, Role.TeamMember, Role.Guest]}
            icon={<CheckOutlined />}
            onClick={() => onVerifyIntegration(domain)}
          />
        </div>
      )}
      {deletable && (
        <div
          data-tooltip-id={TOOLTIP_ROOT_ID}
          data-tooltip-content={
            [Role.BillingManager, Role.TeamMember, Role.Guest].includes(role)
              ? 'Action only allowed for Account Owner and admin'
              : 'Delete domain'
          }
          data-tooltip-place="top"
        >
          <Popconfirm
            title="Deleting domain"
            description={`Are you sure you want to delete ${domain}?`}
            onConfirm={onDelete}
            okType="danger"
            okText="Delete"
            okButtonProps={{ loading: deleteDomainResult.isLoading }}
          >
            <ButtonWithRole
              danger
              disabledFor={[Role.BillingManager, Role.TeamMember, Role.Guest]}
              icon={<DeleteOutlined />}
              loading={deleteDomainResult.isLoading}
            />
          </Popconfirm>
        </div>
      )}
    </Flex>
  );
}

export default React.memo(ActionsCell);
