import { Spin, Flex, message } from 'antd';
import React from 'react';

import './featureRequests.css';
import { useGetAuthTokenQuery } from './upvotyApis';

const UPVOTY_PORTAL_ID = 'https://app.upvoty.com/embed.js?id=f895a042-934f-4e57-afab-8fb3e8be28bb';

export function UpvotyIframe({ token }) {
  return (
    <div className="feature-requests" data-testid="upvoty-iframe-parent">
      <div data-upvoty-portal data-token={token}>
        <Flex align="center" justify="center">
          <Spin size="large" />
        </Flex>
      </div>
    </div>
  );
}

function FeatureRequests() {
  const authTokenResult = useGetAuthTokenQuery();

  React.useEffect(() => {
    let script;
    if (authTokenResult.isSuccess) {
      script = document.createElement('script');

      script.src = UPVOTY_PORTAL_ID;
      script.async = false;
      script.type = 'text/javascript';

      document.body.appendChild(script);
    } else if (authTokenResult.isError) {
      message.error('We are unable to load the feature requests portal at this time. Please try again later.');
    }

    return () => {
      if (script) document.body.removeChild(script);
    };
  }, [authTokenResult.isSuccess, authTokenResult.isError]);

  if (authTokenResult.isError) return null;

  return <UpvotyIframe token={authTokenResult.data?.token} />;
}

export default FeatureRequests;
