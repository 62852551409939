import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography, Table, Flex, Card } from 'antd';
import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import { isFree } from '@prerender/billing-shared';

import { openModal } from '../../../actions/pageActions';
import SearchBarV2 from '../../../components/SearchBarV2';
import { CheckListOptions } from '../../../components/PageElementsLib';
import { getAgeString, getDateTimeTooltip } from '../../../assets/time';
import { DeletePageButton } from '../../../components/PagesActionButtons';
import SeoScoreModal, { SEO_SCORE_MODAL_NAME } from '../../seoScoreModal/seoScoreModal';
import SeoScoreComponent from '../../../components/pages/SeoScoreComponent';
import SeoScoreDetailsModal, { SEO_SCORE_DETAILS_MODAL_NAME } from '../../seoScoreDetailsModal/SeoScoreDetailsModal';
import SeoScoreStatusComponent from '../../../components/pages/SeoScoreStatusComponent';
import { useEvent } from '../../events/hooks/useEvent';
import ExportButton from '../../../components/ExportButton';
import { DomainSearchDropdown } from '../../../components/DomainSearchDropdown';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';
import ExportCachedPagesModal from './ExportCachedPagesModal';
import CachedPagesActionsModal from './CachedPagesActionsModal';
import { useCharbeePlanId, useUserRole } from '../../../hooks/usePrerenderUser';
import PrevNextPagination from '../../../components/PrevNextPagination';
import { useShowNewSeoScore } from '../../../hooks/useShowNewSeoScore';
import { TOOLTIP_ROOT_ID } from '../../../components/Tooltip';

const { Column } = Table;
const { Link } = Typography;

const SHOW_NEVER = true;

const sourcesList = [
  { name: 'site', label: 'Site', tooltip: 'Added through our dashboard or website' },
  { name: 'recache_api', label: 'Recache API', tooltip: 'Added via our API for recaching' },
  { name: 'cdn', label: 'CDN', tooltip: 'Processed by service.prerender.io CDN' },
  { name: 'sitemap', label: 'Sitemap', tooltip: 'Sourced from a sitemap service' },
  { name: 'recache', label: 'Recache', tooltip: 'Auto-recache for removed URLs' },
  { name: 'add_url_api', label: 'API', tooltip: 'Added via our API' },
  { name: '', label: '---' },
];

const cacheStatesList = [
  { name: 'Cached', label: 'Cached', tooltip: 'The URL has been cached' },
  { name: 'Caching...', label: 'Caching...', tooltip: 'The URL is currently being cached' },
  { name: 'Recaching...', label: 'Recaching...', tooltip: 'The URL is currently being recached' },
  { name: '', label: '---' },
];

const seoScoreStatuses = [
  { value: 'improvement', text: 'Improvement' },
  { value: 'passed', text: 'Passed' },
  { value: 'unknown', text: 'Unknown' },
];

function CacheStateView({ cacheStateName }) {
  const cacheState = cacheStatesList.find((s) => s.name === cacheStateName);

  if (!cacheState) return '---';

  return (
    <div data-tooltip-id={TOOLTIP_ROOT_ID} data-tooltip-content={cacheState.tooltip} data-tooltip-place="top">
      {cacheState.label}
    </div>
  );
}

function SourceView({ sourceName }) {
  const source = sourcesList.find((s) => s.name === sourceName);

  if (!source) return '---';

  return (
    <div data-tooltip-id={TOOLTIP_ROOT_ID} data-tooltip-content={source.tooltip} data-tooltip-place="top">
      {source.label}
    </div>
  );
}

function FirstSeenView({ createdAt }) {
  if (!createdAt) return '---';

  const readableDate = new Date(createdAt).toLocaleDateString();
  return (
    <div
      data-tooltip-id={TOOLTIP_ROOT_ID}
      data-tooltip-html={`<span><b>Page First Rendered At</b> <br /> ${readableDate}</span>`}
      data-tooltip-place="top"
    >
      {readableDate}
    </div>
  );
}

const CacheManagerTable = ({
  domains = [],
  onDomainSelected,
  selectedDomain,
  onSearchConditionChanged,
  onSearch,
  currentSearchQuery,
  currentQCondition,
  onReloadData,
  onClearFilters,
  loading,
  cachedPages,
  onSortAndFilter,
  filteredInfo,
  onRequestRecache,
  onDeleteURLs,
  pagination,
}) => {
  const [selectedURLs, setSelectedURLs] = useState([]);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [actionsModalVisible, setActionsModalVisible] = useState(false);

  const dispatch = useDispatch();
  const { track } = useEvent();
  const chargebeePlanId = useCharbeePlanId();
  const userRole = useUserRole();

  const handleTableChange = (_pagination, filters, sorter, details) => {
    onSortAndFilter({
      sortField: sorter.field,
      sortDirection: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      filters,
    });

    const activeFiltersNames = Object.keys(filters).filter((key) => filters[key]?.length > 0);

    if (details.action === 'filter' && activeFiltersNames.length !== 0) {
      track(`Cache URL List Filtered: ${activeFiltersNames}`, {
        subscription_plan: chargebeePlanId,
        filters,
      });
    }
  };

  const deleteURLs = async (urls) => {
    const wasSuccess = await onDeleteURLs(urls);
    if (wasSuccess) {
      setSelectedURLs([]);
      setActionsModalVisible(false);
    }
  };

  const recacheURLs = async (urls) => {
    const wasSuccess = await onRequestRecache(urls);
    if (wasSuccess) {
      setSelectedURLs([]);
      setActionsModalVisible(false);
    }
  };

  const onCheckListOptionsAction = (action) => {
    if (action === 'deleteUrls') {
      deleteURLs(selectedURLs);
    } else if (action === 'requestRecache') {
      recacheURLs(selectedURLs);
    } else if (action === 'openEditUrlsModal') {
      setActionsModalVisible(true);
    }
  };

  const onSeoScoreClick = (url) => {
    if (url.seo_score) {
      dispatch(openModal(SEO_SCORE_MODAL_NAME, <SeoScoreModal cachedUrl={url} />));
      track('SEO Score Clicked', { subscription_plan: chargebeePlanId });
    }
  };

  const onSeoStatusClick = (url) => {
    if (url.seo_score_status !== 'unknown') {
      dispatch(openModal(SEO_SCORE_DETAILS_MODAL_NAME, <SeoScoreDetailsModal url={url} />));
      track('SEO Status Clicked', { subscription_plan: chargebeePlanId });
    }
  };

  const paidPlan = !isFree(chargebeePlanId);
  const showNewScore = useShowNewSeoScore();

  const rowSelection = React.useMemo(() => {
    if (userRole === USER_ROLES.BILLING_MANAGER || userRole === USER_ROLES.GUEST) return false;

    return {
      selectedRowKeys: selectedURLs.map((u) => u.id),
      type: 'checkbox',
      onChange: (_selectedRowKeys, selectedRows) => setSelectedURLs(selectedRows),
      getCheckboxProps: (_record) => ({
        disabled: userRole === USER_ROLES.BILLING_MANAGER || userRole === USER_ROLES.GUEST,
      }),
    };
  }, [userRole, selectedURLs]);

  const cachedToolTip = React.useCallback(
    (adaptive_type) => (
      <div
        data-tooltip-id={TOOLTIP_ROOT_ID}
        data-tooltip-content={`Rendering was optimized for [${
          adaptive_type === 'mobile' ? 'Mobile' : 'Desktop'
        }] crawlers`}
        data-tooltip-place="top"
      >
        {adaptive_type === 'mobile' ? <MobileOutlined /> : <DesktopOutlined />}
      </div>
    ),
    []
  );

  const cachedSeoStatus = React.useCallback(
    (seo_score_status, record) => (
      <Button type="ghost" size="small" onClick={() => onSeoStatusClick(record)}>
        <SeoScoreStatusComponent status={seo_score_status} />
      </Button>
    ),
    []
  );

  const cachedSeoScore = React.useCallback(
    (seo_score, record) => (
      <Button type="ghost" size="small" onClick={() => onSeoScoreClick(record)}>
        <SeoScoreComponent score={seo_score} />
      </Button>
    ),
    []
  );

  return (
    <Fragment>
      <ExportCachedPagesModal visible={exportModalVisible} onCancel={() => setExportModalVisible(false)} />
      <CachedPagesActionsModal
        open={actionsModalVisible}
        urls={selectedURLs}
        onCancel={() => setActionsModalVisible(false)}
        onDelete={deleteURLs}
        onRecache={recacheURLs}
      />
      {/* Cached pages table */}
      <Card
        size="small"
        styles={{ body: { padding: 0 }, header: { padding: 10 } }}
        style={{ padding: 0 }}
        title={
          <Flex align="center" gap="small">
            {domains.length > 1 && paidPlan && (
              <DomainSearchDropdown
                selectedDomain={selectedDomain}
                domains={domains}
                performDomainSearch={onDomainSelected}
              />
            )}
            <SearchBarV2
              initialValue={currentSearchQuery}
              qCondition={currentQCondition}
              onTypingEnd={onSearch}
              onSearchConditionChanged={onSearchConditionChanged}
              disabled={selectedURLs.length > 0}
              placeholder="Please enter at least 3 characters to start your search"
            />
            <div>
              <Button className="ml-2" loading={loading} onClick={onReloadData} size="small">
                <i className="fe fe-rotate-cw"></i>
              </Button>
              <Button className=" ml-2" loading={loading} onClick={onClearFilters} size="small">
                Clear filters
              </Button>
              <ExportButton
                isFreePlan={!paidPlan}
                hasSelectedUrls={selectedURLs.length > 0}
                onClick={() => setExportModalVisible(true)}
              />
            </div>
          </Flex>
        }
      >
        <Table
          rowSelection={rowSelection}
          rowKey="id"
          loading={loading}
          dataSource={cachedPages}
          onChange={handleTableChange}
          pagination={false}
          scroll={{ x: '1600px' }}
          size="small"
          sticky={{ offsetHeader: 0, offsetScroll: 10 }}
        >
          <Column
            title="Content Age"
            dataIndex="last_refresh"
            key="last_refresh"
            align="center"
            width="120px"
            sorter="true"
            defaultSortOrder="descend"
            render={(last_refresh) => (
              <span data-tip={last_refresh && getDateTimeTooltip(last_refresh)}>
                {getAgeString(new Date(last_refresh), 'ago', 'long', SHOW_NEVER)}
              </span>
            )}
          />
          <Column
            title="URL"
            dataIndex="url"
            key="url"
            width="500px"
            ellipsis
            render={(url) => (
              <Link href={url} target="_blank" rel="noreferrer">
                {url}
              </Link>
            )}
          />
          <Column
            title="Device"
            dataIndex="adaptive_type"
            key="adaptive_type"
            align="center"
            width="70px"
            filteredValue={filteredInfo.adaptive_type || null}
            filters={[
              {
                text: 'Desktop',
                value: 'desktop',
              },
              {
                text: 'Mobile',
                value: 'mobile',
              },
            ]}
            render={cachedToolTip}
          />
          {showNewScore ? (
            <Column
              title="SEO Score"
              dataIndex="seo_score_status"
              key="seo_score_status"
              align="center"
              width="150px"
              sorter="true"
              filteredValue={filteredInfo.seo_score_status || null}
              filters={seoScoreStatuses}
              render={cachedSeoStatus}
            />
          ) : (
            <Column
              title="SEO Score"
              dataIndex="seo_score"
              key="seo_score"
              align="center"
              width="100px"
              sorter="true"
              filteredValue={filteredInfo.seo_score || null}
              render={cachedSeoScore}
            />
          )}
          <Column
            title="State"
            dataIndex="cache_state"
            key="cache_state"
            align="center"
            width="100px"
            sorter="true"
            filteredValue={filteredInfo.cache_state || null}
            filters={cacheStatesList
              .filter((s) => s.name)
              .map((s) => ({
                text: s.label,
                value: s.name,
              }))}
            render={(cache_state) => <CacheStateView cacheStateName={cache_state} />}
          />
          <Column
            title="First Seen"
            dataIndex="created_at"
            key="created_at"
            align="center"
            width="100px"
            sorter="true"
            render={(created_at) => <FirstSeenView createdAt={created_at} />}
          />
          <Column
            title="Source"
            dataIndex="source"
            key="source"
            align="center"
            width="90px"
            sorter="true"
            filteredValue={filteredInfo.source || null}
            filters={sourcesList
              .filter((s) => s.name)
              .map((s) => ({
                text: s.label,
                value: s.name,
              }))}
            render={(source) => <SourceView sourceName={source} />}
          />
          <Column
            title="Last crawled at"
            dataIndex="last_delivery_at"
            key="last_delivery_at"
            align="center"
            width="150px"
            sorter="true"
            render={(last_delivery_at) => getAgeString(new Date(last_delivery_at), 'ago', 'long', SHOW_NEVER)}
          />
          <Column
            title=""
            key="action"
            align="right"
            width="150px"
            render={(_, record) => (
              <Flex align="center">
                <ButtonWithRole
                  disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                  size="small"
                  className="ml-2"
                  disabled={cachedPages.inProgress}
                  onClick={() => onRequestRecache([record])}
                >
                  Recache
                </ButtonWithRole>

                <DeletePageButton
                  disabled={cachedPages.inProgress}
                  onClick={() => onDeleteURLs([record])}
                  tooltip="Delete from Prerender Cache"
                />
              </Flex>
            )}
          />
        </Table>
        <PrevNextPagination {...pagination} />
      </Card>
      <CheckListOptions
        count={selectedURLs.length}
        name="URL"
        actions={[
          { name: 'requestRecache', text: 'Recache' },
          { name: 'deleteUrls', icon: 'trash-2' },
          { name: 'openEditUrlsModal', icon: 'more-vertical' },
        ]}
        onsubmit={(action) => onCheckListOptionsAction(action)}
        isActive={!loading}
        onclose={() => setSelectedURLs([])}
      />
    </Fragment>
  );
};

export default CacheManagerTable;
