import _ from 'lodash';
import {
  CLEAR_TEST_RESULT,
  SET_DESKTOP_RENDERING_SETTING,
  SET_EMAIL_PREFERENCES,
  SET_FORCE_HTTPS,
  SET_IN_PROGRESS,
  SET_LOAD_IMAGES,
  SET_MOBILE_RENDERING,
  SET_MOBILE_RENDERING_SETTING,
  SET_ORIGINAL_STATE,
  SET_PAGE_LOAD_TIMEOUT,
  SET_PAUSE_RECACHE,
  SET_RENDER_REGION,
  SET_TESTED,
  SET_TEST_RESULT,
  SET_TEST_SETTINGS_IN_PROGRESS,
} from './actionTypes';

// eslint-disable-next-line max-len
export const defaultDesktopUserAgent =
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/89.0.4389.82 Safari/537.36 Prerender (+https://github.com/prerender/prerender)';
// eslint-disable-next-line max-len
export const defaultMobileUserAgent =
  'Mozilla/5.0 (Linux; Android 11; Pixel 5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.72 Mobile Safari/537.36 Prerender (+https://github.com/prerender/prerender)';

const getRenderSettingDiff = (original, changed) => {
  const diff = {
    viewPort: _.isEqual(original?.viewPort, changed.viewPort) ? undefined : changed.viewPort,
    userAgent: original?.userAgent === changed.userAgent ? undefined : changed.userAgent,
    enabled: original?.enabled === changed.enabled ? undefined : changed.enabled,
  };

  const result = _(diff).omitBy(_.isUndefined).value();

  if (_.isEmpty(result)) return undefined;

  return result;
};

const getRenderSettingsDiff = (original, changed) => {
  const diff = {
    desktop: getRenderSettingDiff(original?.desktop, changed.desktop),
    mobile: getRenderSettingDiff(original?.mobile, changed.mobile),
  };

  const result = _(diff).omitBy(_.isUndefined).value();

  if (_.isEmpty(result)) return undefined;

  return result;
};

const getRenderLocationDiff = (original, changed) => {
  const diff = {
    changeAvailable: original?.changeAvailable === changed.changeAvailable ? undefined : changed.changeAvailable,
    renderRegion: original?.renderRegion === changed.renderRegion ? undefined : changed.renderRegion,
  };

  const result = _(diff).omitBy(_.isUndefined).value();

  if (_.isEmpty(result)) return undefined;

  return result;
};

const getEmailPreferencesDiff = (original, changed) => {
  const diff = {
    send75Percent: original?.send75Percent === changed.send75Percent ? undefined : changed.send75Percent,
    send100Percent: original?.send100Percent === changed.send100Percent ? undefined : changed.send100Percent,
    sendSuggested: original?.sendSuggested === changed.sendSuggested ? undefined : changed.sendSuggested,
    emailReportPeriod:
      original?.emailReportPeriod === changed.emailReportPeriod ? undefined : changed.emailReportPeriod,
    send429Alerts: original?.send429Alerts === changed.send429Alerts ? undefined : changed.send429Alerts,
  };

  const result = _(diff).omitBy(_.isUndefined).value();

  if (_.isEmpty(result)) return undefined;

  return result;
};

const getDiff = (originalState, state) => {
  const diff = {
    emailPreferences: getEmailPreferencesDiff(originalState?.emailPreferences, state.emailPreferences),
    recachePaused: originalState?.recachePaused !== state.recachePaused ? state.recachePaused : undefined,
    renderingSettings: getRenderSettingsDiff(originalState?.renderingSettings, state.renderingSettings),
    pageLoadTimeout: originalState?.pageLoadTimeout !== state.pageLoadTimeout ? state.pageLoadTimeout : undefined,
    loadImages: originalState?.loadImages !== state.loadImages ? state.loadImages : undefined,
    renderLocation: getRenderLocationDiff(originalState?.renderLocation, state.renderLocation),
    forceHTTPS: originalState?.forceHTTPS !== state.forceHTTPS ? state.forceHTTPS : undefined,
  };

  const result = _(diff).omitBy(_.isUndefined).value();

  if (_.isEmpty(result)) return undefined;

  return result;
};

export const getStateFromPrerenderUser = (prerenderUser) => ({
  recachePaused: prerenderUser.userPausedRecaching,
  emailPreferences: prerenderUser.settings.emailPreferences,
  renderingSettings: {
    desktop: {
      viewPort: prerenderUser.settings.customViewport,
      userAgent: prerenderUser.settings.customDesktopUserAgent,
      enabled: true,
    },
    mobile: {
      viewPort: prerenderUser.settings.customMobileViewport,
      userAgent: prerenderUser.settings.customMobileUserAgent,
      enabled: prerenderUser.mobileAdaptive,
    },
  },
  pageLoadTimeout: prerenderUser.settings.pageLoadTimeout / 1000,
  loadImages: prerenderUser.settings.loadImages,
  renderLocation: {
    changeAvailable: prerenderUser.features.includes('render_location_change'),
    renderRegion: prerenderUser.settings.renderRegion,
  },
  forceHTTPS: prerenderUser.settings.forceHTTPS,
});

const initialHealthCheckerState = {
  hasError: false,
  results: [],
};

const initialHealthCheckState = {
  hasError: false,
  status: initialHealthCheckerState,
  header: initialHealthCheckerState,
  body: initialHealthCheckerState,
};

const initialTestResult = {
  inProgress: false,
  tested: false,
  diff: undefined,
  desktop: initialHealthCheckState,
  mobile: initialHealthCheckState,
};

const initialState = {
  inProgress: false,
  originalState: null,
  diff: undefined,
  testResult: initialTestResult,
  emailPreferences: {
    send75Percent: true,
    send100Percent: true,
    sendSuggested: true,
    emailReportPeriod: 'WEEKLY',
    send429Alerts: true,
  },
  renderingSettings: {
    desktop: {
      viewPort: { width: 1440, height: 718 },
      userAgent: defaultDesktopUserAgent,
      enabled: true,
    },
    mobile: {
      viewPort: { width: 375, height: 667 },
      userAgent: defaultMobileUserAgent,
      enabled: false,
    },
  },
  pageLoadTimeout: 20,
  loadImages: false,
  renderLocation: {
    changeAvailable: false,
    renderRegion: 'shared',
  },
  forceHTTPS: false,
};

const settingsReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_ORIGINAL_STATE: {
      if (payload) {
        const originalState = getStateFromPrerenderUser(payload);

        return {
          ...state,
          ...originalState,
          originalState,
          diff: undefined,
        };
      } else {
        return initialState;
      }
    }

    case SET_EMAIL_PREFERENCES: {
      const newState = {
        ...state,
        emailPreferences: payload,
      };

      newState.diff = getDiff(state.originalState, newState);
      return newState;
    }

    case SET_PAUSE_RECACHE: {
      const newState = {
        ...state,
        recachePaused: payload,
      };

      newState.diff = getDiff(state.originalState, newState);
      return newState;
    }

    case SET_MOBILE_RENDERING_SETTING: {
      console.log('Changing mobile rendering setting', payload, '...', state.renderingSettings.mobile);

      const newState = {
        ...state,
        renderingSettings: {
          ...state.renderingSettings,
          mobile: {
            ...state.renderingSettings.mobile,
            ...payload,
          },
        },
      };

      newState.diff = getDiff(state.originalState, newState);
      console.log('New state', newState);
      return newState;
    }

    case SET_DESKTOP_RENDERING_SETTING: {
      const newState = {
        ...state,
        renderingSettings: {
          ...state.renderingSettings,
          desktop: {
            ...state.renderingSettings.desktop,
            ...payload,
          },
        },
      };

      newState.diff = getDiff(state.originalState, newState);
      return newState;
    }

    case SET_MOBILE_RENDERING: {
      const newState = {
        ...state,
        renderingSettings: {
          ...state.renderingSettings,
          mobile: {
            ...state.renderingSettings.mobile,
            enabled: payload,
          },
        },
      };

      newState.diff = getDiff(state.originalState, newState);
      return newState;
    }

    case SET_PAGE_LOAD_TIMEOUT: {
      const newState = {
        ...state,
        pageLoadTimeout: payload,
      };

      newState.diff = getDiff(state.originalState, newState);
      return newState;
    }

    case SET_LOAD_IMAGES: {
      const newState = {
        ...state,
        loadImages: payload,
      };

      newState.diff = getDiff(state.originalState, newState);
      return newState;
    }

    case SET_RENDER_REGION: {
      const newState = {
        ...state,
        renderLocation: {
          ...state.renderLocation,
          renderRegion: payload,
        },
      };

      newState.diff = getDiff(state.originalState, newState);
      return newState;
    }

    case SET_FORCE_HTTPS: {
      const newState = {
        ...state,
        forceHTTPS: payload,
      };

      newState.diff = getDiff(state.originalState, newState);
      return newState;
    }

    case SET_IN_PROGRESS:
      return {
        ...state,
        inProgress: payload,
      };

    case SET_TEST_SETTINGS_IN_PROGRESS:
      return {
        ...state,
        testResult: {
          ...initialTestResult,
          inProgress: payload,
        },
      };

    case CLEAR_TEST_RESULT:
      return {
        ...state,
        testResult: initialTestResult,
      };

    case SET_TEST_RESULT:
      return {
        ...state,
        testResult: {
          inProgress: false,
          diff: state.diff,
          tested: true,
          ...payload,
        },
      };

    default:
      return state;

    case SET_TESTED:
      return {
        ...state,
        testResult: {
          ...state.testResult,
          tested: payload,
        },
      };
  }
};

export default settingsReducer;
