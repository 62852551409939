import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Fragment, useEffect, useState } from 'react';
import { ModalHeader, ModalBodyScroller, ModalFooter } from '../../components/ModalLib';
import { ControlButton } from '../../components/PageElementsLib';
import { Container, Alert } from '../../components/CssFrameworkComponents';
import { MODAL_CANCALLED_ACTION, PREVIOUS_STEP } from './onboardingActions';
import { validateIntegration } from './redux/onboardingActions';
import { TWrapper, TRow, TCell } from '../../components/TableLib';
import ErrorLoader from '../../components/ErrorLoader';
import { clearLastErrorCode } from '../../actions/pageActions';
import { isValidUrl } from '../validators/url';

const VERIFY = 'verify';
const SESSION_STORAGE_ONBOARDING_URL_KEY = 'onboardingTestUrl';

const IntegrationVerificationModalContent = ({
  cm,
  onEvent,
  validationInProgress,
  validateIntegration: doValidateIntegration,
  clearLastErrorCode: doClearLastErrorCode,
  lastErrorCode,
  apiErrTxt,
}) => {
  const didReceiveAction = (event) => {
    const { action, payload } = event;
    switch (action) {
      case VERIFY:
        doClearLastErrorCode();
        doValidateIntegration(payload.url, payload.integrationWizardId);
        break;

      default:
        onEvent(event);
    }
  };

  const [url, setUrl] = useState(sessionStorage.getItem(SESSION_STORAGE_ONBOARDING_URL_KEY));

  useEffect(() => {
    if (url) {
      sessionStorage.setItem(SESSION_STORAGE_ONBOARDING_URL_KEY, url);
    } else {
      sessionStorage.removeItem(SESSION_STORAGE_ONBOARDING_URL_KEY);
    }
  }, [url]);

  useEffect(() => {
    doClearLastErrorCode();
  }, [doClearLastErrorCode]);

  return (
    <Form
      onSubmit={(v) => didReceiveAction({ action: VERIFY, payload: { url: v.url } })}
      render={({ handleSubmit }) => (
        <Fragment>
          <ModalHeader
            modalHeaderLeft={
              <ControlButton
                text={cm.header.btnLeft}
                onclick={() => onEvent({ action: MODAL_CANCALLED_ACTION })}
                disabled={validationInProgress}
              />
            }
            modalHeaderRight={<div className="mx-4" />}
            title={cm.header.title}
          />
          <ModalBodyScroller>
            <TWrapper tableId="cuadd" parentType="modal" showTHead={false} cols={[{ size: 'flex' }]}>
              <ErrorLoader code={lastErrorCode} scrollTop>
                <TRow>
                  <TCell colspan="3" addClass={'text-center p-0'}>
                    <div className="p-2">
                      <Alert
                        modal={true}
                        content={apiErrTxt[lastErrorCode] ? { text: apiErrTxt[lastErrorCode] } : null}
                      />
                    </div>
                  </TCell>
                </TRow>
              </ErrorLoader>
            </TWrapper>
            <Container fluid>
              <form onSubmit={handleSubmit} className="m-0">
                <div className="row align-items-center m-4">
                  <div className="col-12">
                    <h1>{cm.body.title}</h1>
                    <h4>{cm.body.subtitle}</h4>
                    {cm.body.texts.map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                  </div>
                  <div className="col-12 mt-3">
                    <Field
                      name="url"
                      initialValue={url}
                      render={({ input }) => (
                        <input
                          {...input}
                          onChange={(e) => {
                            setUrl(e.target.value);
                            input.onChange(e);
                          }}
                          type="text"
                          className="w-100 form-control"
                          id="url"
                          placeholder="https://example.com"
                          disabled={validationInProgress}
                        />
                      )}
                    ></Field>
                  </div>
                </div>
              </form>
            </Container>
          </ModalBodyScroller>
          <ModalFooter
            modalFooterLeft={
              <ControlButton
                text={cm.footer.btnLeft}
                onclick={() => onEvent({ action: PREVIOUS_STEP })}
                disabled={validationInProgress}
              />
            }
            modalFooterRight={
              <ControlButton
                text={cm.footer.btnRight}
                color="success"
                action="submit"
                // external submit
                onclick={(event) => {
                  handleSubmit(event);
                }}
                disabled={!url || !isValidUrl(url) || validationInProgress}
                loading={validationInProgress ? { color: 'text-light' } : null}
              />
            }
          />
        </Fragment>
      )}
    />
  );
};

function mapStateToProps(state) {
  return {
    validationInProgress: state.onboarding.inProgress,
    lastErrorCode: state.page.lastErrorCode,
    apiErrTxt: state.page.contentData.apiError,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      validateIntegration,
      clearLastErrorCode,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationVerificationModalContent);
